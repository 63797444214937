const fonts = [
  {
    name: 'Roboto',
    value: 'roboto',
  },
  {
    name: 'Inter',
    value: 'inter',
  },
  {
    name: 'Fira Sans',
    value: 'fira-sans',
  },
  {
    name: 'Istok Web',
    value: 'istok-web',
  },
  {
    name: 'Open Sans',
    value: 'open-sans',
  },
  {
    name: 'Golos Text',
    value: 'golos-text',
  },
]

export default fonts
