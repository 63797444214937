<script>
export default {
  name: 'DzenIcon',
}
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.0476C2.47619 8.66667 3.90383 2.95238 11.0476 2C11.0476 7.23809 10.5714 11.0476 2 11.0476Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 11.0476C2.47619 8.66667 3.90383 2.95238 11.0476 2C11.0476 7.23809 10.5714 11.0476 2 11.0476Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 11.0476C21.5238 8.66667 20.0962 2.95238 12.9524 2C12.9524 7.23809 13.4286 11.0476 22 11.0476Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 11.0476C21.5238 8.66667 20.0962 2.95238 12.9524 2C12.9524 7.23809 13.4286 11.0476 22 11.0476Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 12.9504C2.47619 15.3314 3.90383 21.0457 11.0476 21.998C11.0476 16.76 10.5714 12.9504 2 12.9504Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 12.9504C2.47619 15.3314 3.90383 21.0457 11.0476 21.998C11.0476 16.76 10.5714 12.9504 2 12.9504Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 12.9504C21.5238 15.3314 20.0962 21.0457 12.9524 21.998C12.9524 16.76 13.4286 12.9504 22 12.9504Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 12.9504C21.5238 15.3314 20.0962 21.0457 12.9524 21.998C12.9524 16.76 13.4286 12.9504 22 12.9504Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>
