const modes = [
  {
    name: 'Не опубликован',
    value: 'unpublished',
  },
  {
    name: 'Опубликован',
    value: 'published',
  },
  {
    name: 'Обслуживание',
    value: 'maintenance',
  },
]

export default modes
