import axiosIns from '@/libs/axios'

const url = '/domains'

export function getDomain({ id }) {
  return axiosIns.get(`${url}/${id}`)
}

export function getDomains({
  companyID,
  perPage,
  sortedBy,
  orderBy,
  page,
  search,
  searchFields,
  searchJoin,
}) {
  return axiosIns.get(url, {
    params: {
      companyID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function createDomain({
  company_id,
  name,
}) {
  return axiosIns.post(url, {
    company_id,
    name,
  })
}

export function deleteDomain(id) {
  return axiosIns.delete(`${url}/${id}`)
}

export default {
  getDomains,
  deleteDomain,
  createDomain,
}
