<script>
export default {
  name: 'OkIcon',
}
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0241 13.0121C19.0374 12.3113 18.6902 11.9039 18.1268 11.8235C17.7552 11.7704 17.465 11.9436 17.1796 12.1489C15.6932 13.2184 14.033 13.827 12.2082 13.9479C9.86326 14.1033 7.72468 13.4978 5.80917 12.1261C5.70894 12.0502 5.60247 11.9829 5.49088 11.925C4.77122 11.5766 3.98355 12.0775 4.00026 12.8739C4.01384 13.5211 4.31656 14.0101 4.85115 14.3585C6.2085 15.2431 7.67627 15.8549 9.27747 16.1317C9.54565 16.178 9.78587 16.2565 9.87157 16.5558C9.95469 16.8462 9.78802 17.0295 9.6011 17.2108C9.00473 17.7893 8.41034 18.3698 7.81792 18.9524C7.04987 19.7035 6.2822 20.455 5.5149 21.2069C5.1513 21.5654 5.0493 22.0007 5.22059 22.4779C5.38951 22.9484 5.74887 23.2057 6.2434 23.2511C6.6567 23.289 6.98615 23.1112 7.26985 22.8159C8.51986 21.5146 9.7728 20.2162 11.0287 18.9206C11.3704 18.567 11.6534 18.5646 11.9925 18.9141C12.5244 19.4622 13.055 20.0115 13.5843 20.562C14.3366 21.3406 15.0821 22.1259 15.8438 22.8951C16.4881 23.5456 17.5716 23.2809 17.8276 22.4151C17.9684 21.9388 17.8393 21.5235 17.4826 21.1758C16.6711 20.3851 15.861 19.593 15.0522 18.7996C14.4762 18.2362 13.9005 17.6726 13.3248 17.1089C13.1707 16.9579 13.0921 16.7816 13.155 16.5635C13.1802 16.4608 13.2359 16.3681 13.3148 16.2977C13.3937 16.2272 13.4921 16.1824 13.597 16.169C13.6713 16.1556 13.7448 16.1384 13.819 16.1249C15.4081 15.8339 16.8683 15.2243 18.2123 14.3347C18.7287 13.9929 19.0023 13.5033 19.0241 13.0121Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0241 13.0121C19.0374 12.3113 18.6902 11.9039 18.1268 11.8235C17.7552 11.7704 17.465 11.9436 17.1796 12.1489C15.6932 13.2184 14.033 13.827 12.2082 13.9479C9.86326 14.1033 7.72468 13.4978 5.80917 12.1261C5.70894 12.0502 5.60247 11.9829 5.49088 11.925C4.77122 11.5766 3.98355 12.0775 4.00026 12.8739C4.01384 13.5211 4.31656 14.0101 4.85115 14.3585C6.2085 15.2431 7.67627 15.8549 9.27747 16.1317C9.54565 16.178 9.78587 16.2565 9.87157 16.5558C9.95469 16.8462 9.78802 17.0295 9.6011 17.2108C9.00473 17.7893 8.41034 18.3698 7.81792 18.9524C7.04987 19.7035 6.2822 20.455 5.5149 21.2069C5.1513 21.5654 5.0493 22.0007 5.22059 22.4779C5.38951 22.9484 5.74887 23.2057 6.2434 23.2511C6.6567 23.289 6.98615 23.1112 7.26985 22.8159C8.51986 21.5146 9.7728 20.2162 11.0287 18.9206C11.3704 18.567 11.6534 18.5646 11.9925 18.9141C12.5244 19.4622 13.055 20.0115 13.5843 20.562C14.3366 21.3406 15.0821 22.1259 15.8438 22.8951C16.4881 23.5456 17.5716 23.2809 17.8276 22.4151C17.9684 21.9388 17.8393 21.5235 17.4826 21.1758C16.6711 20.3851 15.861 19.593 15.0522 18.7996C14.4762 18.2362 13.9005 17.6726 13.3248 17.1089C13.1707 16.9579 13.0921 16.7816 13.155 16.5635C13.1802 16.4608 13.2359 16.3681 13.3148 16.2977C13.3937 16.2272 13.4921 16.1824 13.597 16.169C13.6713 16.1556 13.7448 16.1384 13.819 16.1249C15.4081 15.8339 16.8683 15.2243 18.2123 14.3347C18.7287 13.9929 19.0023 13.5033 19.0241 13.0121Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8542 11.3715C14.7556 11.2418 17.0161 8.84098 16.8878 6.02564C16.7561 3.13818 14.3632 0.885465 11.5541 1.00451C8.66053 1.12714 6.39679 3.53108 6.52046 6.34996C6.64684 9.23047 9.05394 11.4967 11.8542 11.3715Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8542 11.3715C14.7556 11.2418 17.0161 8.84098 16.8878 6.02564C16.7561 3.13818 14.3632 0.885465 11.5541 1.00451C8.66053 1.12714 6.39679 3.53108 6.52046 6.34996C6.64684 9.23047 9.05394 11.4967 11.8542 11.3715Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.2148 6.21533C14.2298 4.84588 13.1103 3.70146 11.7348 3.67999C10.3676 3.65865 9.21974 4.77463 9.1919 6.15218C9.16413 7.52635 10.3072 8.69514 11.6864 8.7027C12.3499 8.70265 12.9868 8.44186 13.4597 7.97657C13.9327 7.51127 14.2039 6.87873 14.2148 6.21533Z"
      stroke="#4B465C"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.2148 6.21533C14.2298 4.84588 13.1103 3.70146 11.7348 3.67999C10.3676 3.65865 9.21974 4.77463 9.1919 6.15218C9.16413 7.52635 10.3072 8.69514 11.6864 8.7027C12.3499 8.70265 12.9868 8.44186 13.4597 7.97657C13.9327 7.51127 14.2039 6.87873 14.2148 6.21533Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>
