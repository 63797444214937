<template>
  <div class="a-color-picker">
    <div
      v-for="(color_value, name) in colors"
      :key="name"
      class="a-color-picker__item"
      :class="{
        active: color === name
      }"
      @click="selectColor(name)"
    >
      <div class="a-color-picker__item__name">
        {{ name }}
      </div>
      <div
        class="a-color-picker__item__color"
        :style="{ background: color_value, borderColor: color_value, color: color_value }"
      >
        <feather-icon
          v-if="color === name"
          icon="CheckIcon"
          size="25"
          :color="'inherit'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'

export default {
  name: 'AColorPicker',
  props: {
    value: {
      type: String,
      default: 'blue',
    },
    palette: {
      type: String,
      default: 'primary',
    },
  },
  setup(props, { emit }) {
    const color = ref(props.value)

    const palettes = {
      primary: {
        blue: '#00CFE8',
        indigo: '#6610F2',
        purple: '#7367F0',
        pink: '#E83E8C',
        red: '#EA5455',
        orange: '#FD7E14',
        yellow: '#FF9F43',
        green: '#28C76F',
        teal: '#20C997',
        cyan: '#00CFE8',
        black: '#000000',
      },
      secondary: {
        blue: '#99ECF6',
        indigo: '#C7C2F9',
        purple: '#E9E7FD',
        pink: '#F7BBBB',
        red: '#F29899',
        orange: '#FFB269',
        yellow: '#FFD9B4',
        green: '#7EDDA9',
        teal: '#A9E9C5',
        cyan: '#99ECF6',
        black: '#000000',
      },
    }

    const colors = computed(() => palettes[props.palette])

    watch(() => props.value, val => {
      color.value = val
    })

    watch(() => color.value, val => {
      emit('input', val)
    })
    const selectColor = newValue => {
      color.value = newValue
    }

    return {
      color,
      colors,
      selectColor,
    }
  },
}
</script>

<style scoped lang="scss">
.a-color-picker{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 15px;
  grid-row-gap: 15px;

  &__item{
    cursor: pointer;
    &__name{
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      white-space: nowrap;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__color{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px auto 0;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      transition: all .2s ease-in-out;
    }
    &:hover &__color{
      box-shadow: 0px 8px 25px #82868B;
      color: #ffffff !important;
    }

    &.active:not(:hover) &__color{
      background: transparent !important;
      border: 2px solid;
    }
  }
}
</style>
