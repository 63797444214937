import axiosIns from '@/libs/axios'

export function getWebsite({ id, includes }) {
  return axiosIns.get(`/websites/stores/websites/${id}`, {
    params: {
      includes,
    },
  })
}

export function getWebsites({
  projectID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/stores/websites', {
    params: {
      projectID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createWebsite({
  project_id,
  domain,
  name,
  key,
  description,
  mode,
  dev_data,
}) {
  return axiosIns.post('/websites/stores/websites', {
    project_id,
    domain,
    name,
    key,
    description,
    mode,
    dev_data,
  })
}

export function updateWebsite({
  id,
  domain,
  name,
  key,
  description,
  mode,
  dev_data,
}) {
  return axiosIns.patch(`/websites/stores/websites/${id}`, {
    domain,
    name,
    key,
    description,
    mode,
    dev_data,
  })
}

export function deleteWebsite(id) {
  return axiosIns.delete(`/websites/stores/websites/${id}`)
}

export default {
  getWebsites,
  getWebsite,
  createWebsite,
  updateWebsite,
  deleteWebsite,
}
