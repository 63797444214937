<script>
export default {
  name: 'ViberIcon',
}
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 21.0007L4.65 17.2007C2.03818 13.4931 2.59303 8.4214 5.94471 5.36623C9.29638 2.31105 14.3977 2.22699 17.8482 5.17008C21.2987 8.11316 22.0203 13.1639 19.5321 16.9555C17.0439 20.7471 12.1232 22.095 8.05 20.1007L3 21.0007"
      stroke="#4B465C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 21.0007L4.65 17.2007C2.03818 13.4931 2.59303 8.4214 5.94471 5.36623C9.29638 2.31105 14.3977 2.22699 17.8482 5.17008C21.2987 8.11316 22.0203 13.1639 19.5321 16.9555C17.0439 20.7471 12.1232 22.095 8.05 20.1007L3 21.0007"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 10C9 10.2761 9.22386 10.5 9.5 10.5C9.77614 10.5 10 10.2761 10 10V9C10 8.72386 9.77614 8.5 9.5 8.5C9.22386 8.5 9 8.72386 9 9V10C9 12.7614 11.2386 15 14 15H15C15.2761 15 15.5 14.7761 15.5 14.5C15.5 14.2239 15.2761 14 15 14H14C13.7239 14 13.5 14.2239 13.5 14.5C13.5 14.7761 13.7239 15 14 15"
      stroke="#4B465C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 10C9 10.2761 9.22386 10.5 9.5 10.5C9.77614 10.5 10 10.2761 10 10V9C10 8.72386 9.77614 8.5 9.5 8.5C9.22386 8.5 9 8.72386 9 9V10C9 12.7614 11.2386 15 14 15H15C15.2761 15 15.5 14.7761 15.5 14.5C15.5 14.2239 15.2761 14 15 14H14C13.7239 14 13.5 14.2239 13.5 14.5C13.5 14.7761 13.7239 15 14 15"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
